import React, { useState, useEffect } from "react";
import { Card, Dialog, DialogContent, Typography, Grid } from "@mui/material";

import SearchBar from "../../components/SearchBar.js"; // Make sure to import your SearchBar component
import AddTile from "../../components/AddTile.js";
import MetaDataTile from "../../components/MetaDataTile.js";
import SortableItem from "../../components/SortableItem.js";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import useApi from "../../hooks/useApi.js";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveIcon from "@mui/icons-material/Remove";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import MovieIcon from "@mui/icons-material/Movie";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import CollectionMetaDataTile from "./Metadata.js";
import LibraryModal from "../library/LibraryModal.js";
import { toast } from "react-toastify";

const Collection = ({
  list,
  backgroundColor,
  onDelete,
  display = "modal",
  onClose,
  fullScreen,
}) => {
  const {
    addMovieToList,
    removeMovieFromList,
    replaceMovieInList,
    reorderMoviesInList,
  } = useApi();
  const queryClient = useQueryClient();
  const { list_id, size } = list;
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [tileToReplace, setTileToReplace] = useState(null);
  const [action, setAction] = useState("");
  const [draggableId, setDraggableId] = useState(null);
  const [libraryModalId, setLibraryModalId] = useState(null);
  const [isPortrait, setIsPortrait] = useState(
    display === "inline" ? true : false
  ); // for collection metadata component

  // const tileWidth = { xs: 100, md: 170 };
  const maxTileWidth = 200;
  // const minTileWidth = 150;

  const [editedList, setEditedList] = useState(list);
  const numberOfItems = size
    ? size
    : list.movies.length
    ? list.movies.length
    : 1;

  useEffect(() => {
    // Update state to use the latest data whenever list changes
    setEditedList(list);
  }, [list, numberOfItems, display]);

  const updateEditedList = (name, value) => {
    setEditedList((prevList) => ({
      ...prevList,
      [name]: typeof value === "function" ? value(prevList[name]) : value,
    }));
  };

  const handleOpenSearchDialog = (tile, action) => {
    setOpenSearchDialog(true);
    setTileToReplace(tile);
    setAction(action);
  };

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
    setTileToReplace(null);
    setAction("");
  };

  const addMovieMutation = useMutation({
    mutationFn: (params) => addMovieToList(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["defaultList"] });
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const removeMovieMutation = useMutation({
    mutationFn: (params) => removeMovieFromList(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["defaultList"] });
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const replaceMovieMutation = useMutation({
    mutationFn: (params) => replaceMovieInList(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["defaultList"] });
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const reorderListMutation = useMutation({
    mutationFn: (params) => reorderMoviesInList(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["defaultList"] });
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleAdd = (searchValue) => {
    addMovieMutation.mutate({
      listId: list_id,
      tmdbId: searchValue.tmdb_id,
    });
    handleCloseSearchDialog();
  };

  const handleReplace = (searchValue) => {
    replaceMovieMutation.mutate({
      listId: list_id,
      oldTmdbId: tileToReplace.tmdbId,
      newTmdbId: searchValue.tmdb_id,
    });
    handleCloseSearchDialog();
  };

  const handleRemove = (tmdbId) => {
    removeMovieMutation.mutate({
      listId: list_id,
      tmdbId,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setDraggableId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setDraggableId(null);
      updateEditedList("movies", (currentMovies) => {
        const oldIndex = currentMovies.findIndex(
          (tile) => tile.tmdbId === active.id
        );
        const newIndex = currentMovies.findIndex(
          (tile) => tile.tmdbId === over.id
        );

        const newMovies = arrayMove(currentMovies, oldIndex, newIndex).map(
          (tile, index) => ({
            ...tile,
            position: index + 1,
          })
        );

        reorderListMutation.mutate({ listId: list_id, movies: newMovies });

        return newMovies;
      });
    }
  }

  const gridValLg =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3 // tick
      ? 3
      : isPortrait && numberOfItems === 4 // tick
      ? 2.4
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3 // tick
      ? 4
      : !isPortrait && numberOfItems === 4 // tick
      ? 3
      : !isPortrait && numberOfItems === 5 // tick
      ? 2.4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 2
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValMd =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3 // tick
      ? 3
      : isPortrait && numberOfItems === 4 // tick
      ? 4
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3 // tick
      ? 4
      : !isPortrait && numberOfItems === 4 // tick
      ? 3
      : !isPortrait && numberOfItems === 5 // tick
      ? 4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 3
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValSm =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 4
      : isPortrait && numberOfItems === 3
      ? 3
      : isPortrait && numberOfItems === 4
      ? 3
      : isPortrait && numberOfItems >= 5 // tick
      ? 2
      : !isPortrait && numberOfItems === 2
      ? 6
      : !isPortrait && numberOfItems === 3
      ? 4
      : !isPortrait && numberOfItems === 4
      ? 6
      : !isPortrait && numberOfItems === 5 // tick
      ? 4
      : !isPortrait && numberOfItems >= 6 // tick
      ? 4
      : numberOfItems === 1
      ? 6
      : undefined;

  const gridValXs =
    isPortrait && numberOfItems === 1
      ? 6
      : isPortrait && numberOfItems === 2
      ? 6
      : isPortrait && numberOfItems === 3
      ? 6
      : isPortrait && numberOfItems === 4
      ? 6
      : isPortrait && numberOfItems >= 5
      ? 6
      : !isPortrait && numberOfItems === 2
      ? 4
      : !isPortrait && numberOfItems === 3
      ? 4
      : !isPortrait && numberOfItems === 4
      ? 4
      : !isPortrait && numberOfItems === 5
      ? 4
      : !isPortrait && numberOfItems >= 6
      ? 4
      : numberOfItems === 1
      ? 4
      : undefined;

  const maxWidthLg = (12 / gridValLg) * maxTileWidth;
  const maxWidthMd = (12 / gridValMd) * maxTileWidth;
  const maxWidthSm = (12 / gridValSm) * maxTileWidth;
  // const maxWidthXs = (12 / gridValXs) * maxTileWidth; // not currently being used

  return (
    <>
      <Card
        elevation={0}
        sx={{
          p: 1.5,
          backgroundColor: backgroundColor,
          // width: numberOfItems === 1 && !isPortrait ? "100%" : "100%",
          width: "100%",
          maxWidth: { sm: maxWidthSm, md: maxWidthMd, lg: maxWidthLg },
          minHeight:
            numberOfItems === 1 && !fullScreen && !isPortrait
              ? "334.5px"
              : undefined,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid
          container
          spacing={
            display === "modal"
              ? 2
              : list.size === 1
              ? 0.5
              : list.size === 5
              ? 2.5
              : 2
          }
          justifyContent={numberOfItems === 1 && !fullScreen && "center"}
          // spacing={2}
        >
          <Grid
            item
            xs={!isPortrait ? 12 : gridValXs}
            sm={!isPortrait ? 12 : gridValSm}
            md={!isPortrait ? 12 : gridValMd}
            lg={!isPortrait ? 12 : gridValLg}
          >
            <CollectionMetaDataTile
              editedList={editedList}
              updateEditedList={updateEditedList}
              setEditedList={setEditedList}
              originalList={list}
              onDelete={onDelete}
              onAdd={() => handleOpenSearchDialog(null, "add")}
              isPortrait={isPortrait}
              setIsPortrait={setIsPortrait}
              onCollectionClose={onClose}
              fullScreen={fullScreen}
            />
          </Grid>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={editedList.movies.map((tile) => tile.tmdbId)}
              strategy={rectSortingStrategy}
            >
              {editedList.movies.map((tile) => {
                // console.log("tile", tile);
                return (
                  <Grid
                    key={tile.tmdbId}
                    item
                    xs={gridValXs}
                    sm={gridValSm}
                    md={gridValMd}
                    lg={gridValLg}
                  >
                    <SortableItem key={tile.tmdbId} id={tile.tmdbId}>
                      {({ attributes, listeners }) => (
                        <MetaDataTile
                          tmdbId={tile.tmdbId}
                          onClick={() => setLibraryModalId(tile.tmdbId)}
                          position={
                            editedList.is_ranked ? tile.position : undefined
                          }
                          onHover={[
                            ...(editedList.movies.length > 1
                              ? [
                                  {
                                    label: "Drag to reorder",
                                    value: "reorder",
                                    icon: (
                                      <DragIndicatorIcon
                                        {...attributes}
                                        {...listeners}
                                      />
                                    ),
                                  },
                                ]
                              : []),
                            {
                              label: "Replace",
                              value: "replace",
                              icon: <RefreshIcon />,
                              onClick: (event) => {
                                event.stopPropagation();
                                handleOpenSearchDialog(tile, "replace");
                              },
                            },
                            ...(editedList.size === null
                              ? [
                                  {
                                    label: "Remove",
                                    value: "remove",
                                    icon: <RemoveIcon />,
                                    onClick: (event) => {
                                      event.stopPropagation();
                                      handleRemove(tile.tmdbId);
                                    },
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    </SortableItem>
                  </Grid>
                );
              })}
            </SortableContext>

            <DragOverlay>
              {draggableId ? <MetaDataTile tmdbId={draggableId} /> : null}
            </DragOverlay>
          </DndContext>

          {size &&
            Array.from(
              { length: size - editedList.movies.length },
              (_, index) => (
                <Grid
                  item
                  key={index}
                  xs={gridValXs}
                  sm={gridValSm}
                  md={gridValMd}
                  lg={gridValLg}
                  // sx={{ border: "1px solid red" }}
                >
                  <AddTile
                    onClick={() => handleOpenSearchDialog(null, "add")}
                    profile={display === "inline"}
                  />
                </Grid>
              )
            )}

          {editedList.movies.length === 0 && !size && (
            <Grid
              item
              xs={gridValXs}
              sm={gridValSm}
              md={gridValMd}
              lg={gridValLg}
              // sx={{ border: "1px solid red" }}
            >
              <AddTile
                onClick={() => handleOpenSearchDialog(null, "add")}
                profile={display === "inline"}
              />
            </Grid>
          )}

          {fullScreen &&
            [1, 2].includes(editedList.movies.length) &&
            Array.from({ length: 3 - editedList.movies.length }).map(
              (_, index) => (
                <Grid
                  key={`invisible-add-tile-${index}`}
                  item
                  xs={gridValXs}
                  sx={{
                    visibility: "hidden", // Makes it invisible but reserves space
                  }}
                >
                  <AddTile profile={display === "inline"} />
                </Grid>
              )
            )}
        </Grid>
      </Card>

      <Dialog
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            paddingTop: "25vh",
          },
        }}
        disableRestoreFocus={true}
        // !!! Todo: remove when live, currently needed to fix a bug that stops textfields
        // within dialogs from autofocusing with React strict mode enabled
      >
        <DialogContent
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2">
            {action === "replace" ? (
              <>
                Replace <strong>{tileToReplace.title}</strong> with...
              </>
            ) : (
              <>
                Add movie to <strong>{list.title}</strong>
              </>
            )}
          </Typography>
          <SearchBar
            onSelect={action === "replace" ? handleReplace : handleAdd}
            enableQuickAdd={false}
          />
        </DialogContent>
      </Dialog>

      {!!libraryModalId && (
        <LibraryModal
          open={!!libraryModalId}
          tmdbId={libraryModalId}
          onClose={() => setLibraryModalId(null)}
        />
      )}
    </>
  );
};

export default Collection;
