import React from "react";
import {
  Stack,
  Chip,
  Card,
  CardContent,
  Typography,
  CardActionArea,
} from "@mui/material";
import { formatDate, getYear } from "../../utils/utils";

function LibraryList({ movie, onClick }) {
  return (
    <Card onClick={onClick} elevation={0}>
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "0px 4px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                maxWidth: {
                  xs: "260px",
                  sm: "600px",
                  md: "800px",
                },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {movie?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{
                fontStyle: "italic",
                display: { xs: "none", md: "inline-flex" },
              }}
            >
              {getYear(movie?.releaseDate)}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            <Chip
              label={formatDate(movie?.timestamp)}
              size="small"
              sx={{
                backgroundColor: "transparent",
                color: "text.secondary",
                // display: { xs: "none", md: "inline-flex" },
              }}
            />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default LibraryList;
