import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

export const HeaderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "mobile",
})(({ theme, variant, mobile }) => ({
  height: "38px",
  boxShadow: "none", // Default to no box shadow

  // Conditional styles based on the variant
  ...(variant === "contained" && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
    },
  }),
  ...(variant === "outlined" && {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  // ...(mobile && {
  //   ".MuiButton-startIcon": {
  //     marginRight: 0,
  //   },
  // }),
}));

HeaderButton.defaultProps = {
  variant: "contained",
};

export const HeaderToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButton-root": {
      border: "none",
      borderRadius: theme.shape.borderRadius,
    },
  })
);

export const HeaderToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.main, 0.3),
  },
  // "& .MuiTouchRipple-child": {
  //   backgroundColor: theme.palette.secondary.main, // Custom ripple color
  // },
}));
