import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { motion } from "framer-motion";

export default function AddList({ onClose, onAdd }) {
  const { createList } = useApi();
  const queryClient = useQueryClient();
  const [limitSize, setLimitSize] = useState(false);

  const [newList, setNewList] = useState({
    title: "",
    description: "",
    isDefault: false,
    isRanked: false,
    size: null,
  });

  const updateNewList = (name, value) => {
    setNewList((prevList) => ({
      ...prevList,
      [name]: typeof value === "function" ? value(prevList[name]) : value,
    }));
  };

  const handleLimitSizeSwitch = (event) => {
    setLimitSize(event.target.checked);
    updateNewList("size", event.target.checked ? "" : null);
  };

  const handleSizeChange = (event) => {
    const newSize = event.target.value;
    if (limitSize) {
      if (newSize === "") {
        // Allow empty string to clear the field
        updateNewList("size", newSize);
      } else {
        const sizeValue = parseInt(newSize, 10);
        if (sizeValue > 0) {
          updateNewList("size", sizeValue);
        } else {
          toast.error("Size must be a number greater than zero");
        }
      }
    }
  };

  const createListMutation = useMutation({
    mutationFn: (params) => createList(params),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
      onAdd?.(data.id);
      onClose?.();
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleCreate = () => {
    createListMutation.mutate(newList);
  };

  return (
    <Box
      sx={{
        width: { xs: "350px", sm: "400px" },
        display: "flex",
        position: "relative",
        flexDirection: "column",
        gap: 1,
        maxHeight: "400px",
        overflow: "auto",
        p: 2,
      }}
    >
      <TextField
        id="list-title-input"
        variant="standard"
        hiddenLabel={true}
        placeholder="Collection name"
        fullWidth
        autoFocus
        multiline
        maxRows={3}
        value={newList.title || ""}
        onChange={(event) => updateNewList("title", event.target.value)}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontSize: (theme) => theme.typography.h6.fontSize,
            fontWeight: (theme) => theme.typography.h6.fontWeight,
            fontFamily: (theme) => theme.typography.h6.fontFamily,
            lineHeight: (theme) => theme.typography.h6.lineHeight,
          },
        }}
      />

      <TextField
        id="list-description-input"
        variant="standard"
        hiddenLabel={true}
        placeholder="Description"
        fullWidth
        multiline
        rows={3}
        value={newList.description || ""}
        onChange={(event) => updateNewList("description", event.target.value)}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
            fontWeight: (theme) => theme.typography.body2.fontWeight,
            fontFamily: (theme) => theme.typography.body2.fontFamily,
            lineHeight: (theme) => theme.typography.body2.lineHeight,
            p: 0,
          },
        }}
      />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Tooltip title="Show your collection as a ranked list">
            <FormControlLabel
              control={
                <Switch
                  checked={newList.isRanked}
                  onChange={() => updateNewList("isRanked", !newList.isRanked)}
                />
              }
              label={<Typography variant="body2">Ranked</Typography>}
            />
          </Tooltip>

          <Tooltip title="Set a size limit for your collection, e.g. 5 for a top 5">
            <FormControlLabel
              control={
                <Switch checked={limitSize} onChange={handleLimitSizeSwitch} />
              }
              label={<Typography variant="body2">Capped</Typography>}
            />
          </Tooltip>

          {limitSize && (
            <TextField
              id="list-size-input"
              variant="filled"
              hiddenLabel={true}
              placeholder="Size"
              size="small"
              sx={{ width: "60px" }}
              value={newList.size || ""}
              onChange={handleSizeChange}
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                  fontWeight: (theme) => theme.typography.body2.fontWeight,
                  fontFamily: (theme) => theme.typography.body2.fontFamily,
                  lineHeight: (theme) => theme.typography.body2.lineHeight,
                },
              }}
            />
          )}
        </Stack>

        <Stack direction="row" spacing={0.5} alignItems="center">
          <Tooltip title="Create">
            <motion.div
              animate={{ scale: newList.title ? [1, 1.2, 1] : 1 }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
              }}
            >
              <IconButton
                onClick={handleCreate}
                disabled={createListMutation.isPending || !newList.title}
                sx={{
                  p: 0.5,
                  color: newList.title ? "primary.main" : "default",
                }}
              >
                <AddIcon />
              </IconButton>
            </motion.div>
          </Tooltip>
        </Stack>
      </Box>

      <Tooltip title="Cancel">
        <IconButton
          onClick={() => {
            onClose?.();
          }}
          sx={{ p: 0.5, position: "absolute", top: 10, right: 14 }}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
