import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PersonIcon from "@mui/icons-material/Person";
import { useAuth } from "../context/authContext";
import { useTheme } from "@emotion/react";
import { useThemeContext } from "../context/themeContext";

export default function SettingsMenu({
  anchorElement,
  open,
  handleClose,
  handleOpenPrefs,
}) {
  const theme = useTheme();
  const { toggleMode } = useThemeContext();
  const { logout } = useAuth();

  return (
    <Menu
      anchorEl={anchorElement}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose} // ensures menu closes if non-button bit of menu is clicked
      MenuListProps={{ onMouseLeave: handleClose }}
      transformOrigin={{ horizontal: "left", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
    >
      <MenuItem onClick={handleOpenPrefs}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        Profile
      </MenuItem>
      <MenuItem onClick={toggleMode}>
        <ListItemIcon>
          {theme.palette.mode === "dark" ? (
            <LightModeIcon fontSize="small" />
          ) : (
            <DarkModeIcon fontSize="small" />
          )}
        </ListItemIcon>
        {theme.palette.mode === "dark" ? "Light mode" : "Dark mode"}
      </MenuItem>
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
}
