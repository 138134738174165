import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { Button, Tooltip } from "@mui/material";

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
    mode,
  } = props;

  const button = (
    <Button
      variant="text"
      id={id}
      // startIcon={}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{
        color: mode === "add" ? "text.secondary" : "text.primary",
        p: 0,
        "&.Mui-disabled": {
          pointerEvents: "none",
          color: "text.primary",
        },
      }}
    >
      {label ? label : "Undated"}
    </Button>
  );

  return disabled ? (
    button
  ) : (
    <Tooltip title="Edit watch date">{button}</Tooltip>
  );
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ ...props.slots, field: ButtonField }}
      disableFuture
      slotProps={{
        ...props.slotProps,
        field: { setOpen, mode: props.mode },
        actionBar: {
          actions: ["today", "clear", "accept"], // "accept", "cancel"
        },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function PickerWithButtonField({
  value,
  setValue,
  mode,
  disabled,
}) {
  const formattedValue = value ? dayjs(value) : null;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      {/* Todo: update locale based on user preferences!!! */}
      <ButtonDatePicker
        label={
          formattedValue == null ? null : formattedValue.format("DD MMM YYYY")
        }
        value={formattedValue}
        onChange={setValue}
        mode={mode}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
