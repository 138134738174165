import {
  Box,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditOffIcon from "@mui/icons-material/EditOff";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export default function CollectionMetadata({
  editedList,
  updateEditedList,
  setEditedList,
  originalList,
  onDelete,
  onAdd,
  isPortrait,
  setIsPortrait,
  onCollectionClose,
  fullScreen,
}) {
  const { editListDetails, deleteList } = useApi();
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [limitSize, setLimitSize] = useState(Boolean(editedList.size));

  const showAddButton = !editedList.size
    ? true
    : editedList.movies.length < editedList.size
    ? true
    : false;

  const editListMutation = useMutation({
    mutationFn: (params) => editListDetails(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
      setIsEditing(false);
      setIsEdited(false);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
      setEditedList(originalList);
      setIsEditing(false);
      setIsEdited(false);
    },
  });

  const deleteListMutation = useMutation({
    mutationFn: (params) => deleteList(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "collections", "infinite"],
      });
      onDelete?.();
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleSaveEdit = () => {
    editListMutation.mutate({
      listId: editedList.list_id,
      title: editedList.title,
      description: editedList.description,
      isRanked: editedList.is_ranked,
      size: editedList.size,
    });
  };

  const handleTitleChange = (event) => {
    updateEditedList("title", event.target.value);
    setIsEdited(true);
  };

  const handleDescriptionChange = (event) => {
    updateEditedList("description", event.target.value);
    setIsEdited(true);
  };

  const toggleLimitSizeSwitch = (event) => {
    setLimitSize(event.target.checked);
    updateEditedList("size", event.target.checked ? "" : null);
    setIsEdited(true);
  };

  const handleSizeChange = (event) => {
    const newSize = event.target.value;
    if (limitSize) {
      if (newSize === "") {
        // Allow empty string to clear the field
        updateEditedList("size", newSize);
        setIsEdited(true);
      } else {
        const sizeValue = parseInt(newSize, 10);
        if (sizeValue > 0) {
          updateEditedList("size", sizeValue);
          setIsEdited(true);
        } else {
          toast.error("Size must be a number greater than zero");
        }
      }
    }
  };

  const toggleShowRanking = () => {
    updateEditedList("is_ranked", !editedList.is_ranked);
    setIsEdited(true);
  };

  const handleOpenMenu = (event) => {
    setLimitSize(Boolean(editedList.size));
    setMenuAnchorEl(event.currentTarget);
  };

  const handleEdit = () => {
    setMenuAnchorEl(null);
    setIsEditing(true);
  };

  const closeButton = (
    <Tooltip title="Close">
      <IconButton
        onClick={onCollectionClose}
        sx={{
          p: 0.5,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pb: isPortrait ? 1.5 : undefined,
          position: "relative",
          mr: isPortrait && originalList.size === 1 && 3, // applies extra margin to top pick of the month collection only in profile page
        }}
      >
        {!isEditing ? (
          <Box sx={{ pr: isPortrait ? 0 : { xs: 4, sm: 14 } }}>
            <Typography
              sx={{
                textWrap: "balance",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: isPortrait ? 3 : undefined,
                typography: { xs: "h6", sm: "body1", lg: "h6" },
                fontWeight: { xs: "bold", sm: "bold", lg: "bold" },
              }}
            >
              {editedList.title}{" "}
            </Typography>
          </Box>
        ) : (
          <TextField
            id="list-title-input"
            variant="filled"
            hiddenLabel={true}
            placeholder="List title"
            fullWidth
            autoFocus
            multiline
            maxRows={2}
            value={editedList.title || ""}
            onChange={handleTitleChange}
            InputProps={{
              disableUnderline: true,
              sx: {
                fontSize: (theme) => theme.typography.h6.fontSize,
                fontWeight: {
                  xs: "bold",
                  sm: "bold",
                  md: "bold",
                  lg: "normal",
                },
                fontFamily: (theme) => theme.typography.h6.fontFamily,
                lineHeight: (theme) => theme.typography.h6.lineHeight,
                p: 0.5,
              },
            }}
          />
        )}

        {!isEditing && !!editedList.description && (
          <Typography
            variant="body2"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: isPortrait ? 5 : undefined,
              // pr: 8,
            }}
          >
            {editedList.description}{" "}
          </Typography>
        )}

        {isEditing && (
          <TextField
            id="list-description-input"
            variant="filled"
            hiddenLabel={true}
            placeholder="List description"
            fullWidth
            multiline
            rows={5}
            value={editedList.description || ""}
            onChange={handleDescriptionChange}
            InputProps={{
              disableUnderline: true,
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
                fontWeight: (theme) => theme.typography.body2.fontWeight,
                fontFamily: (theme) => theme.typography.body2.fontFamily,
                lineHeight: (theme) => theme.typography.body2.lineHeight,
                p: 0.5,
              },
            }}
          />
        )}

        {!editedList.is_default && !isEditing && (
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              ml: fullScreen && -0.5,
              position: isPortrait
                ? undefined
                : { xs: undefined, sm: "absolute" },
              top: isPortrait ? undefined : 0,
              right: isPortrait ? undefined : 0,
            }}
          >
            {showAddButton && (
              <Tooltip title="Add item to collection">
                <IconButton
                  onClick={onAdd}
                  sx={{
                    p: 0.5,
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Manage collection">
              <IconButton onClick={handleOpenMenu} sx={{ p: 0.5 }}>
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
            {!fullScreen && closeButton}
          </Stack>
        )}

        {fullScreen && !isEditing && (
          <Box sx={{ position: "absolute", top: -1, right: 0 }}>
            {closeButton}
          </Box>
        )}

        {isEditing && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={editedList.is_ranked}
                    onChange={toggleShowRanking}
                  />
                }
                label={<Typography variant="body2">Ranked</Typography>}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={limitSize}
                    onChange={toggleLimitSizeSwitch}
                  />
                }
                label={<Typography variant="body2">Size</Typography>}
              />
              {limitSize && (
                <TextField
                  id="list-size-input"
                  variant="filled"
                  hiddenLabel={true}
                  placeholder="Size"
                  size="small"
                  sx={{ width: "60px" }}
                  value={editedList.size || ""}
                  onChange={handleSizeChange}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      fontSize: (theme) => theme.typography.body2.fontSize,
                      fontWeight: (theme) => theme.typography.body2.fontWeight,
                      fontFamily: (theme) => theme.typography.body2.fontFamily,
                      lineHeight: (theme) => theme.typography.body2.lineHeight,
                    },
                  }}
                />
              )}
            </Stack>

            <Stack
              direction="row"
              spacing={0.5}
              alignItems="center"
              sx={{ ml: -0.5 }}
            >
              <Tooltip title="Save">
                <motion.div
                  animate={{ scale: isEdited ? [1, 1.1, 1] : 1 }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                  }}
                >
                  <IconButton
                    onClick={handleSaveEdit}
                    disabled={editListMutation.isPending || !isEdited}
                    sx={{
                      p: 0.5,
                      color: isEdited ? "primary.main" : "default",
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </motion.div>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  onClick={() => {
                    setIsEditing(false);
                    setIsEdited(false);
                  }}
                  sx={{ p: 0.5 }}
                >
                  <EditOffIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        )}
      </Box>

      <Menu
        id={"collection-menu"}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem dense onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          dense
          onClick={() => deleteListMutation.mutate(editedList.list_id)}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
        {/* <MenuItem
          dense
          onClick={() => {
            setIsPortrait(!isPortrait);
            setMenuAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <VisibilityIcon />
          </ListItemIcon>
          <ListItemText primary="Toggle view" />
        </MenuItem> */}
      </Menu>
    </>
  );
}
