import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useApi from "../hooks/useApi";
import Loading from "../components/Loading.js";

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { exchangeCodeForTokens } = useApi();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const oneTimeCode = params.get("code");
    const isNewUser = params.get("new_user") === "True";

    if (!oneTimeCode) {
      return;
    }

    const handleOAuthCallback = async () => {
      try {
        const { access_token, refresh_token } = await exchangeCodeForTokens(
          oneTimeCode
        );
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        access_token &&
          refresh_token &&
          queryClient.invalidateQueries(["currentUser"]);
        isNewUser
          ? navigate("/app/library?newUser=true")
          : navigate("/app/library");
      } catch (error) {
        console.log("Error in handleOAuthCallback: ", error);
      }
    };

    handleOAuthCallback();
  }, [exchangeCodeForTokens, location, navigate, queryClient]);

  return <Loading />;
};

export default OAuthCallback;
