// External imports
import React, { useState, useEffect, useMemo } from "react";
import { Box, Dialog, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import { alpha } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Internal imports
import useApi from "../../hooks/useApi";
import MovieCard from "../../components/MovieCard";
import JournalEntryFixed from "./JournalEntryFixed";
import JournalEntryEditable from "./JournalEntryEditable";

function LibraryModal({ open, tmdbId, entryId = null, onClose }) {
  const { fetchJournalForMovie, fetchStatus } = useApi();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [editingEntryId, setEditingEntryId] = useState(null);
  const [status, setStatus] = useState("loading");
  const [showAllEntries, setShowAllEntries] = useState(false);
  const [logAgain, setLogAgain] = useState(false);

  const statusQuery = useQuery({
    queryKey: ["movie", "status", tmdbId],
    queryFn: () => fetchStatus(tmdbId),
  });

  useEffect(() => {
    if (statusQuery.isSuccess) {
      setStatus(statusQuery.data.status);
    }
  }, [statusQuery.isSuccess, statusQuery.data]);

  const journalQuery = useQuery({
    queryKey: ["movie", "journal", tmdbId],
    queryFn: () => fetchJournalForMovie(tmdbId),
  });

  const journal = useMemo(() => {
    return Array.isArray(journalQuery.data) ? journalQuery.data : [];
  }, [journalQuery.data]);

  const isEmptyEntry = (entry) => {
    return (
      entry.rating === null &&
      (entry.reactions?.length === 0 || entry.reactions === null) &&
      !entry.notes
    );
  };

  useEffect(() => {
    // Show journal entries created in last 5 seconds as editable
    // Ensures automatically created entries on status changes prompt further input
    if (journal.length > 0 && isEmptyEntry(journal[0])) {
      const timestamp = new Date(journal[0].timestamp);
      const currentTime = new Date();
      const timeDiff = (currentTime - timestamp) / 1000;
      if (timeDiff <= 5) {
        setEditingEntryId(journal[0].journal_id);
      }
    }
  }, [journal]);

  const handleStatusChange = (option) => {
    setStatus(option);
  };

  const handleCloseModal = () => {
    setEditingEntryId(null);
    onClose?.();
  };

  return (
    <>
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="library-card-dialog"
        fullWidth
        scroll="body"
        PaperProps={{
          sx: {
            maxWidth: 700,
            boxShadow: "none",
            overflow: "visible",
            backgroundImage: "none", // needed to remove default overlay MUI adds in dark mode to desaturate colour
            backgroundColor: fullscreen
              ? alpha(theme.palette.common.black, 0.5)
              : "transparent",
          },
        }}
      >
        <Box sx={{ px: "5%", py: "5%" }}>
          <Box sx={{ mb: 2 }}>
            <MovieCard
              tmdbId={tmdbId}
              onClose={handleCloseModal}
              status={status}
              onStatusChange={handleStatusChange}
              setLogAgain={setLogAgain}
              onDeleteStatus={handleCloseModal}
            />
          </Box>

          {logAgain && (
            <Box sx={{ mb: 2 }}>
              <JournalEntryEditable
                mode="add"
                tmdbId={tmdbId}
                onAdd={() => setLogAgain(false)}
                isMobile={fullscreen}
              />
            </Box>
          )}

          {entryId &&
            journal.length > 0 &&
            journal.map((entry) => {
              if (entry.journal_id === entryId) {
                return (
                  <Box sx={{ mb: 2 }} key={entry.journal_id}>
                    {editingEntryId === entry.journal_id ? (
                      <JournalEntryEditable
                        tmdbId={entry.tmdbId}
                        existingEntry={entry}
                        mode="edit"
                        onEdit={() => setEditingEntryId(null)}
                        onDelete={() => {
                          journalQuery.refetch();
                          setEditingEntryId(null);
                        }}
                        isMobile={fullscreen}
                        // onAdd={() => setEditingEntryId(null)}
                      />
                    ) : (
                      <JournalEntryFixed
                        entry={entry}
                        onEdit={() => setEditingEntryId(entry.journal_id)}
                        isLoading={journalQuery.isFetching}
                        isMobile={fullscreen}
                      />
                    )}
                  </Box>
                );
              }
              return null;
            })}

          {(!entryId || showAllEntries) &&
            journal
              .filter(
                (entry) => entryId === null || entry.journal_id !== entryId
              )
              .map((entry) => (
                <Box sx={{ mb: 2 }} key={entry.journal_id}>
                  {editingEntryId === entry.journal_id ? (
                    <JournalEntryEditable
                      tmdbId={entry.movie_tmdb_id}
                      existingEntry={entry}
                      mode="edit"
                      onEdit={() => setEditingEntryId(null)}
                      isMobile={fullscreen}
                      onDelete={() => {
                        journalQuery.refetch();
                        // ^^ Journal query does not refetch without this despite
                        // query key being invalidated in delete mutation 🤯
                        setEditingEntryId(null);
                      }}
                    />
                  ) : (
                    <JournalEntryFixed
                      entry={entry}
                      onEdit={() => setEditingEntryId(entry.journal_id)}
                      isLoading={journalQuery.isFetching}
                      isMobile={fullscreen}
                    />
                  )}
                </Box>
              ))}

          {entryId && journal?.length > 1 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title={showAllEntries ? "Show less" : "Show more"}>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.tertiary.main,
                  }}
                  onClick={() => setShowAllEntries(!showAllEntries)}
                >
                  {showAllEntries ? (
                    <ExpandLessIcon
                      sx={{ color: theme.palette.common.white }}
                      fontSize="small"
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{ color: theme.palette.common.white }}
                      fontSize="small"
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

export default LibraryModal;
