import React from "react";
import { Popover, IconButton, Tooltip, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmDeletePopover = ({ anchorEl, onCancel, onConfirm }) => (
  <Popover
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onCancel}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <Box sx={{ p: 2 }}>
      <Typography variant="body1" gutterBottom>
        Are you sure you want to delete? This action cannot be undone.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Tooltip title="Confirm">
          <IconButton color="success" onClick={onConfirm}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton color="error" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  </Popover>
);

export default ConfirmDeletePopover;
