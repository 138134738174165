import { Box, Popover, Typography } from "@mui/material";
import React from "react";

export default function Bio({ open, anchorEl, onClose, content }) {
  return (
    <Popover
      id="bio-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{ mt: 1 }}
    >
      <Box sx={{ p: 2, maxWidth: "300px" }}>
        {/* <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={0}>
            <Typography>
              {" "}
              <b>299</b>{" "}
            </Typography>
            <Typography> followers </Typography>
          </Stack>
          <Stack direction="column" spacing={0}>
            <Typography>
              {" "}
              <b>374</b>{" "}
            </Typography>
            <Typography> following </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1, mt: 1 }} /> */}
        <Typography>{content}</Typography>
      </Box>
    </Popover>
  );
}
