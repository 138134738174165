import React, { useState } from "react";
import { Chip, Autocomplete, TextField } from "@mui/material";
import { reactionOptions } from "../utils/utils.js";

export default function ChipInput({ selectedTags, onTagsChange }) {
  const [inputValue, setInputValue] = useState(""); // controls value of textfield input

  return (
    <Autocomplete
      multiple
      id="reaction-input"
      options={reactionOptions}
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.category}
      value={selectedTags}
      inputValue={inputValue}
      // freeSolo
      disableCloseOnSelect
      fullWidth
      // sx={{ paddingBottom: 1 }}
      onChange={(event, selectedOptions) => {
        // fires when an input is selected from dropdown (or typed and entered in freesolo)
        const selectedValues = selectedOptions.map((option) =>
          typeof option === "object" ? option.label : option
        ); // extract the reaction values from the reaction object
        onTagsChange(selectedValues); // update the reaction state with updated reaction values
        setInputValue(""); // clears textfield input
      }}
      renderTags={(value, getTagProps) =>
        value.map((value, index) => {
          // Find the corresponding object from reactionOptions based on the label
          const option = reactionOptions.find(
            (option) => option.label === value
          );
          return (
            <Chip
              variant="filled"
              icon={
                option.icon || (
                  <span role="img" aria-label={option.label}>
                    {option.emoji}
                  </span>
                )
              }
              label={value}
              size="small"
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(
        params // renders input field where user can type
      ) => (
        <>
          <TextField
            {...params}
            variant="standard"
            placeholder={selectedTags.length === 0 ? "Add reactions" : ""}
            size="small"
            fullWidth
            // placeholder={inputValue ? "" : "Press enter to add"}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              sx: {
                ".MuiInputBase-input": {
                  fontSize: "0.875rem",
                  position: "relative",
                },
              },
            }}
            onChange={(event) => {
              const value = event.target.value || "";
              setInputValue(value);
            }} // inputValue state updated as the user types
          />
        </>
      )}
      isOptionEqualToValue={(option, value) => option.label === value}
      renderOption={(props, option) => (
        <li {...props}>
          <Chip
            variant="filled"
            icon={
              option.icon || (
                <span role="img" aria-label={option.label}>
                  {option.emoji}
                </span>
              )
            }
            label={option.label}
            size="small"
          />
        </li>
      )}
    />
  );
}
