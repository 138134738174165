import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  // Box,
  Avatar,
  Grid,
  Typography,
  // Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  Divider,
  Collapse,
  IconButton,
  Stack,
} from "@mui/material";
// import MovieIcon from "@mui/icons-material/Movie";
// import MenuBookIcon from "@mui/icons-material/MenuBook";
// import LiveTvIcon from "@mui/icons-material/LiveTv";
// import PodcastsIcon from "@mui/icons-material/Podcasts";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "../../context/authContext.js";
import useApi from "../../hooks/useApi.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatDate, getStatusOptions } from "../../utils/utils.js";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useTheme } from "@emotion/react";
import { useThemeContext } from "../../context/themeContext.js";
import { toast } from "react-toastify";

const ProfilePreferencesModal = ({ open, onClose, newUser = false }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { setMode } = useThemeContext();
  const { checkUsernameAvailability, editProfile, deleteUser } = useApi();
  const queryClient = useQueryClient();
  const statusOptions = getStatusOptions(theme);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [username, setUsername] = useState(user.username || "");
  const [usernameError, setUsernameError] = useState("");
  const [bio, setBio] = useState(
    user.bio || `Curating since ${formatDate(user.firstJoined, "dropDay")}`
  );
  // const [contentTypes, setContentTypes] = useState(() => []);
  const [darkModePref, setDarkModePref] = useState(user.preferences.darkMode);
  const [addButtonPref, setAddButtonPref] = useState(
    user.preferences.addButton
  );
  const bioCharacterLimit = 150;
  const [deleteAccountExpanded, setDeleteAccountExpanded] = useState(false);
  const [deleteAccountInput, setDeleteAccountInput] = useState("");
  const isDeleteAccountConfirmed =
    deleteAccountInput.trim().toLowerCase() === "delete";
  const handleDeleteAccountExpanded = () => {
    setDeleteAccountExpanded((prev) => !prev);
    setDeleteAccountInput("");
  };
  const handleDeleteAccountInput = (event) => {
    setDeleteAccountInput(event.target.value);
  };

  const editProfileMutation = useMutation({
    mutationFn: ({ username, bio, darkModePref, addButtonPref }) =>
      editProfile({ username, bio, darkModePref, addButtonPref }),
    onSuccess: () => {
      queryClient.invalidateQueries(["currentUser"]);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleDarkModePref = (event, darkModeValue) => {
    if (darkModeValue !== null) {
      const mode = darkModeValue ? "dark" : "light";
      setMode(mode);
      setDarkModePref(darkModeValue);
    }
  };

  const handleAddButtonPref = (event, value) => {
    if (value !== null) {
      setAddButtonPref(value);
    }
  };

  const handleClose = () => {
    setDeleteAccountExpanded(false);
    setDeleteAccountInput("");
    onClose?.();
  };

  // const handleContentTypes = (event, newContentTypes) => {
  //   setContentTypes(newContentTypes);
  // };

  // React.useEffect(() => {
  //   if (profileQuery.data) {
  //     setContentTypes(Object.keys(profileQuery.data.content));
  //   }
  // }, [profileQuery.data]);

  // const handleContentTypeChange = (type) => {
  //   setContentTypes((prevState) => ({
  //     ...prevState,
  //     [type]: !prevState[type],
  //   }));
  // };

  const handleUsernameChange = async (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);

    if (newUsername === user.username) {
      setUsernameError("");
      return;
    }

    const isAvailable = await checkUsernameAvailability(newUsername);
    if (!isAvailable) {
      setUsernameError("Username already taken");
    } else {
      setUsernameError("");
    }
  };

  const handleSave = () => {
    if (!username) {
      setUsernameError("Please choose a username");
      return; // Prevent proceeding with save
    }
    editProfileMutation.mutate({ username, bio, darkModePref, addButtonPref });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullscreen}>
      <DialogTitle>
        {newUser
          ? `Hey, ${user.givenName}! Let's set up your profile`
          : "Profile settings"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              alt={user.givenName}
              src={user?.picture}
              sx={{
                width: { xs: 80, md: 120 },
                height: { xs: 80, md: 120 },
                bgcolor: "tertiary.main",
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="username-input"
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              onChange={handleUsernameChange}
              error={!!usernameError}
              helperText={usernameError || undefined}
              FormHelperTextProps={{
                sx: { textAlign: "right" },
              }}
            />

            <TextField
              id="bio-input"
              margin="dense"
              label="Bio"
              type="text"
              fullWidth
              multiline
              maxRows={3}
              variant="standard"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              helperText={`${bio.length}/${bioCharacterLimit}`}
              inputProps={{ maxLength: bioCharacterLimit }}
              FormHelperTextProps={{
                sx: { textAlign: "right" },
              }}
            />
          </Grid>
          {/* <Grid item xs={3}>
              <Typography variant="body2" sx={{ textAlign: "right" }}>
                {" "}
                {newUser ? "I want to curate" : "Currently curating"}{" "}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <ToggleButtonGroup
                value={contentTypes}
                onChange={handleContentTypes}
                fullWidth
                size="small"
              >
                <ToggleButton value="movie">
                  <Tooltip title="Movies">
                    <MovieIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="book" disabled>
                  <Tooltip title="Books">
                    <MenuBookIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="tv" disabled>
                  <Tooltip title="TV Shows">
                    <LiveTvIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="podcast" disabled>
                  <Tooltip title="Podcasts">
                    <PodcastsIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="youtube" disabled>
                  <Tooltip title="YouTube videos">
                    <YouTubeIcon />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid> */}

          <Grid item xs={3}>
            <Typography variant="body2" sx={{ textAlign: "right" }}>
              {" "}
              Default theme
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <ToggleButtonGroup
              value={darkModePref}
              exclusive
              size="small"
              fullWidth
              onChange={handleDarkModePref}
              aria-label="change view"
            >
              <ToggleButton value={false} aria-label="light mode">
                <LightModeIcon sx={{ mr: 1 }} /> Light
              </ToggleButton>
              <ToggleButton value={true} aria-label="dark mode">
                <DarkModeIcon sx={{ mr: 1 }} /> Dark
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {!newUser && (
            <>
              <Grid item xs={3}>
                <Typography variant="body2" sx={{ textAlign: "right" }}>
                  {" "}
                  Add button action
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ToggleButtonGroup
                  value={addButtonPref}
                  exclusive
                  size="small"
                  fullWidth
                  onChange={handleAddButtonPref}
                  aria-label="change view"
                >
                  <ToggleButton value="queued" aria-label="queue on add">
                    {React.cloneElement(statusOptions.queued.icon, {
                      sx: { color: statusOptions.queued.color, mr: 1 },
                    })}{" "}
                    {statusOptions.queued.action}
                  </ToggleButton>
                  <ToggleButton value="finished" aria-label="finish on add">
                    {React.cloneElement(statusOptions.finished.icon, {
                      sx: { color: statusOptions.finished.color, mr: 1 },
                    })}{" "}
                    {statusOptions.finished.action}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </>
          )}
          {!newUser && (
            <>
              <Grid item xs={12} sx={{ mb: -1 }}>
                <Divider />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" sx={{ textAlign: "right" }}>
                  Delete account
                </Typography>
              </Grid>
              <Grid item xs={9} sx={{ mt: { xs: 0, md: -1 } }}>
                <IconButton
                  onClick={handleDeleteAccountExpanded}
                  size="small"
                  aria-expanded={deleteAccountExpanded}
                  aria-label="Expand delete account section"
                >
                  <ExpandMoreIcon
                    sx={{
                      transform: deleteAccountExpanded
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                      transition: "transform 0.3s",
                    }}
                  />
                </IconButton>
                <Collapse in={deleteAccountExpanded} sx={{ mt: 1 }}>
                  <Typography variant="body2">
                    Your Curate account will be deleted and disconnected from
                    your Google account. This action is irreversible.
                  </Typography>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    spacing={2}
                    sx={{ mt: 1 }}
                  >
                    <TextField
                      id="delete-account-input"
                      fullWidth
                      variant="standard"
                      margin="dense"
                      type="text"
                      placeholder="Type 'delete' to confirm"
                      value={deleteAccountInput}
                      onChange={handleDeleteAccountInput}
                      autoComplete="off"
                    />
                    <Button
                      variant="contained"
                      color="error"
                      disabled={!isDeleteAccountConfirmed}
                      onClick={deleteUser}
                    >
                      Confirm
                    </Button>
                  </Stack>
                </Collapse>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!newUser && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={
            editProfileMutation.isPending ||
            !!usernameError ||
            !bio ||
            isDeleteAccountConfirmed
          }
        >
          {" "}
          {newUser ? "Let's go!" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilePreferencesModal;
