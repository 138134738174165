import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Loading from "./Loading";

const ProtectedRoute = ({ children }) => {
  const { user, isCheckingAuth } = useAuth();

  if (isCheckingAuth) {
    return <Loading />; // Or any other loading indicator
  }

  if (!user) {
    // User not logged in, redirect to home page
    console.log("User not logged in, redirecting to home");
    return <Navigate to="/" replace />;
  }

  // User is logged in, allow access to the route
  return children;
};

export default ProtectedRoute;
