import React, { useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

const labels = {
  0.5: "atrocious",
  1: "very poor",
  1.5: "poor",
  2: "disappointing",
  2.5: "mediocre",
  3: "good",
  3.5: "very good",
  4: "great",
  4.5: "exceptional",
  5: "favourite",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function HoverRating({
  value,
  onChange,
  readOnly = false,
  isMobile = false,
}) {
  const [hover, setHover] = useState(-1);

  return (
    <Box
      sx={{
        // width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Rating
        onMouseEnter={(e) => e.currentTarget.focus()}
        name="hover-feedback"
        value={value}
        precision={0.5}
        readOnly={readOnly}
        sx={{ p: "6px 0px" }}
        getLabelText={getLabelText}
        onChange={onChange}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {hover !== -1 && (
        <Box
          sx={{
            ml: isMobile ? 0 : 2,
            fontSize: "0.875rem",
          }}
        >
          {labels[hover]}
        </Box>
      )}
    </Box>
  );
}
