import React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";

function HideOnScroll(props) {
  const { children } = props;
  // const scrollContainer = () => document.getElementById("scrollableDiv");
  // const trigger = useScrollTrigger({
  //   target: scrollContainer() ? scrollContainer() : undefined,
  // });
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default HideOnScroll;
