import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
  // Dialog,
  // DialogContent,
  // IconButton,
} from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as TrackIcon } from "../assets/IconTrack.svg";
import { ReactComponent as CurateIcon } from "../assets/IconCurate.svg";
import { ReactComponent as ExploreIcon } from "../assets/IconExplore.svg";

const getImagePath = (imageName, isMobile, isDarkMode) => {
  const device = isMobile ? "mobile" : "desktop";
  const theme = isDarkMode ? "light" : "dark";
  return `/images/${device}/${theme}/${imageName}.png`;
};

const imageNames = [
  "curate",
  "track",
  "collect",
  "react",
  "rank",
  "review",
  "explore",
];

const alts = [
  "Screenshot of the Curate feature",
  "Screenshot of the Track feature",
  "Screenshot of the Collect feature",
  "Screenshot of the React feature",
  "Screenshot of the Rank feature",
  "Screenshot of the Review feature",
  "Screenshot of the Explore feature",
];

const actions = [
  "Curate",
  "Track",
  "Collect",
  "React",
  "Rank",
  "Review",
  "Explore",
];

const icons = [
  <CurateIcon />,
  <TrackIcon />,
  <CurateIcon />,
  <TrackIcon />,
  <CurateIcon />,
  <TrackIcon />,
  <ExploreIcon />,
];

const content = [
  [
    "Showcase your favourites and share your tastes with the world",
    "Keep your profile fresh - monthly and yearly picks reset, so your current favourites are always on display",
  ],
  [
    "Build your personal content library, intuitively organised by status: queued, started, abandoned and finished",
    "Manage your 'to watch/read/listen/see' list effortlessly with the queued status",
  ],
  [
    "Curate your own unique collections of the best (and worst!) of what you've experienced",
    "See how your choices stack up against your friends in featured collections released each week",
  ],
  [
    "Assign ratings from a half star to five stars and tag content with reactions and/or kudos",
    "Segment your library with rich filters and uncover hidden preferences",
  ],
  [
    "Rank your collections and challenge friends to compare",
    "Or keep your collections unranked if you prefer to enjoy everything equally",
  ],
  [
    "Leave a note whenever you queue, start, abandon or finish something",
    "Write full reviews or quick notes— perfect for remembering your thoughts and experiences",
  ],
  [
    "Ditch generic AI suggestions and discover personalised recommendations from users with similar tastes",
    "Follow your friends and browse collections and recommendations curated from popular publications",
  ],
];

export default function FeatureCard() {
  const theme = useTheme();
  const [activeImage, setActiveImage] = useState(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDarkMode = theme.palette.mode === "dark";
  const contentRefs = useRef([]);
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const images = imageNames.map((name) =>
    getImagePath(name, isMobile, isDarkMode)
  );

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the content block is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Update the active image based on the index of the visible content block
          const index = contentRefs.current.indexOf(entry.target);
          setActiveImage(index);
        }
      });
    }, observerOptions);

    // Create a copy of the current refs
    const currentRefs = [...contentRefs.current];

    // Observe all content blocks
    currentRefs.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      // Cleanup observer on unmount using the copied refs
      currentRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <Grid container maxWidth="lg" spacing={{ xs: 1, sm: 2 }}>
        <Grid
          item
          xs={6}
          md={8}
          className="imageContainer"
          sx={{
            height: "100vh", // Matches viewport height
            position: "sticky", // Makes the element stick within the viewport
            top: 0, // Sticks to the top of the viewport
            // border: "1px solid blue",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={images[activeImage]}
            alt={alts[activeImage]}
            width="100%"
            style={{
              // border: "1px solid green",
              transition: "opacity 1s ease-in-out", // Smooth transition for image change
              // cursor: activeImage === images.length - 1 ? "default" : "pointer",
            }}
            // onClick={activeImage === images.length - 1 ? null : handleClickOpen}
          />
        </Grid>

        <Grid item xs={6} md={4} className="contentContainer">
          {content.map((textArray, index) => (
            <Box
              key={index}
              ref={(el) => (contentRefs.current[index] = el)}
              sx={{
                height: "100vh",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
                ml: { xs: 0, sm: 2 },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ xs: "center", md: "flex-start" }}
                spacing={-5}
                mb={2}
              >
                <SvgIcon
                  sx={{
                    width: { xs: 75, sm: 100 },
                    height: { xs: 75, sm: 100 },
                    color: "primary.main",
                  }}
                >
                  {icons[index]}
                </SvgIcon>
                <Typography variant="h3" color="text.primary">
                  <b>{actions[index]}</b>
                </Typography>
              </Stack>
              {textArray.map((text, textIndex) => (
                <Typography key={textIndex} sx={{ textWrap: "balance", mb: 2 }}>
                  {text}
                </Typography>
              ))}
            </Box>
          ))}
        </Grid>
      </Grid>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            backgroundImage: "none",
          },
        }}
      >
        <DialogContent sx={{ position: "relative", p: 0 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              bgcolor: (theme) => theme.palette.tertiary.main,
              color: (theme) => theme.palette.tertiary.contrastText,
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={images[activeImage]}
            alt={alts[activeImage]}
            width="100%"
            style={{ display: "block" }}
          />
        </DialogContent>
      </Dialog> */}
    </>
  );
}
