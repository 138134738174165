import {
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import useApi from "../hooks/useApi.js";

import ProfileHeader from "../features/profile/ProfileHeader.js";

export default function Explore({ toggleDrawer }) {
  const { fetchActivityFeed } = useApi();

  const feedInfiniteQuery = useInfiniteQuery({
    queryKey: ["activityFeed", "infinite"],
    queryFn: ({ pageParam }) => fetchActivityFeed({ pageParam, self: true }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const feed =
    feedInfiniteQuery.data?.pages.flatMap((page) => page.items) || [];

  if (feedInfiniteQuery.isPending)
    return (
      <div>
        <CircularProgress />
      </div>
    );
  if (feedInfiniteQuery.isError)
    return (
      <div>
        <Typography> {feedInfiniteQuery.error.message} </Typography>
      </div>
    );

  // console.log(feed);

  return (
    <>
      <ProfileHeader toggleDrawer={toggleDrawer} />

      <InfiniteScroll
        dataLength={feed.length}
        next={feedInfiniteQuery.fetchNextPage}
        hasMore={!!feedInfiniteQuery.hasNextPage}
        loader={
          <Box sx={{ pt: 2, pb: 2 }}>
            <LinearProgress />
          </Box>
        }
        // scrollableTarget="scrollableDiv"
        style={{ overflow: "visible" }}
        // Overflow visbile required to ensure framer-motion scale animation applied to library cards displays properly
      >
        <Grid container spacing={2}>
          {feed.map((activity) => {
            return (
              <Grid item key={activity.id} xs={12}>
                <Typography sx={{ pt: 5, pb: 5 }}>
                  {" "}
                  {activity.user_given_name} performed an {activity.action} of
                  type {activity.type}{" "}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </>
  );
}
