import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import useApi from "../hooks/useApi.js";
import ProfileHeader from "../features/profile/ProfileHeader.js";
import ProfilePicks from "../features/profile/ProfilePicks.js";
import Loading from "../components/Loading.js";

export default function Profile({ toggleDrawer }) {
  const { fetchDefaultList } = useApi();
  const [activeTab, setActiveTab] = useState("movie");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const monthlyTop1Query = useQuery({
    queryKey: ["defaultList", "monthly"],
    queryFn: () => fetchDefaultList("movie", "top1_monthly"),
    enabled: true,
  });

  const yearlyTop3Query = useQuery({
    queryKey: ["defaultList", "yearly"],
    queryFn: () => fetchDefaultList("movie", "top3_yearly"),
    enabled: true,
  });

  const alltimeTop5Query = useQuery({
    queryKey: ["defaultList", "allTime"],
    queryFn: () => fetchDefaultList("movie", "top5_alltime"),
    enabled: true,
  });

  if (
    monthlyTop1Query.isPending ||
    yearlyTop3Query.isPending ||
    alltimeTop5Query.isPending
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (
    monthlyTop1Query.isError ||
    yearlyTop3Query.isError ||
    alltimeTop5Query.isError
  ) {
    return (
      <div>
        <Typography>
          {" "}
          {monthlyTop1Query.error?.message ||
            yearlyTop3Query.error?.message ||
            alltimeTop5Query.error?.message}{" "}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <ProfileHeader
        toggleDrawer={toggleDrawer}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />

      <ProfilePicks
        top1List={monthlyTop1Query.data}
        top3List={yearlyTop3Query.data}
        top5List={alltimeTop5Query.data}
      />
    </>
  );
}
