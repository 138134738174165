import React from "react";
import { useAuth } from "../context/authContext";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Link as ReactRouterLink } from "react-router-dom";
import profilePic from "../assets/profile-pic-purple.png";
import {
  Badge,
  Chip,
  Grid,
  Stack,
  useTheme,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import TheaterComedyRoundedIcon from "@mui/icons-material/TheaterComedyRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LogoLargeLight from "../assets/LogoLargeLight.svg";
import LogoLargeDark from "../assets/LogoLargeDark.svg";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { useThemeContext } from "../context/themeContext";
import HideOnScroll from "../components/HideOnScroll";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import FeatureSection from "../components/FeatureSection";

const Home = () => {
  const theme = useTheme();
  const { user, login } = useAuth();
  const [openJoinDialog, setOpenJoinDialog] = React.useState(false);
  const [openDonateDialog, setOpenDonateDialog] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const { toggleMode } = useThemeContext();

  return (
    <>
      <HideOnScroll>
        <Tooltip
          title={
            theme.palette.mode === "dark"
              ? "Switch to dark mode"
              : "Switch to light mode"
          }
          placement="left"
        >
          <IconButton
            onClick={toggleMode}
            sx={{
              position: "fixed",
              top: 16,
              right: 16,
            }}
          >
            {theme.palette.mode === "dark" ? (
              <LightModeIcon />
            ) : (
              <DarkModeIcon />
            )}
          </IconButton>
        </Tooltip>
      </HideOnScroll>

      <Box
        id="landing-section"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          gap: 3,
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ width: { xs: "90%", sm: "50%", lg: "40%" } }}>
          <img
            src={theme.palette.mode === "dark" ? LogoLargeDark : LogoLargeLight}
            alt="Curate Logo"
            sx={{ pb: 10 }}
            width="100%"
          />
        </Box>

        <Typography color="text.primary" textAlign="center">
          <b>Track</b> the content you consume. <b>Curate</b> your favourites.{" "}
          <b>Explore</b> recommendations.
        </Typography>
        {/* above needs to be horizontally centered on the screen and each sentence should appear on a separate line */}

        <Stack direction={{ md: "row", xs: "column" }} spacing={3}>
          {user ? (
            <Button
              variant="contained"
              component={ReactRouterLink}
              to="/app/library"
            >
              Enter app
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<GoogleIcon />}
              onClick={login}
            >
              Sign in with Google
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={() => scrollToSection("intro-section")}
          >
            Learn more
          </Button>
        </Stack>
      </Box>

      <Box
        id="intro-section"
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.main,
          py: { xs: "100px", md: "100px" },
          px: { xs: 2, md: 4, lg: 0 },
          display: "flex",
          justifyContent: "center",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Grid
          container
          spacing={5}
          sx={{
            maxWidth: "lg",
          }}
        >
          <Grid item xs={12} md={8}>
            <Typography variant="h3" gutterBottom color="text.primary">
              <b>Your personal content vault</b>
            </Typography>
            <Typography>
              Curate is a living library of your content consumption, so you can
              keep track of the stuff you enjoyed, the stuff to avoid,
              recommendations to explore and discover content you might not have
              seen before
            </Typography>
          </Grid>
          <Grid item container xs={12} md={4} spacing={3}>
            <Grid item xs={4} textAlign="center">
              <Badge color="primary" variant="dot">
                <MovieRoundedIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>Movies</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: theme.palette.tertiary.main,
                  },
                }}
              >
                <MenuBookRoundedIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>Books</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: theme.palette.tertiary.main,
                  },
                }}
              >
                <PodcastsRoundedIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>Podcasts</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: theme.palette.tertiary.main,
                  },
                }}
              >
                <LiveTvRoundedIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>TV</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: theme.palette.tertiary.main,
                  },
                }}
              >
                <YouTubeIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>YouTube</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Badge
                variant="dot"
                sx={{
                  "& .MuiBadge-dot": {
                    backgroundColor: theme.palette.tertiary.main,
                  },
                }}
              >
                <TheaterComedyRoundedIcon fontSize="large" sx={{ pb: 0.5 }} />
              </Badge>
              <Typography>Theatre</Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Stack
                direction="row"
                spacing={1}
                display="flex"
                justifyContent={{ xs: "center", md: "center" }}
              >
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-dot": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                >
                  <Chip
                    variant="filled"
                    size="small"
                    label="live"
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    }}
                  />
                </Badge>
                <Badge
                  variant="dot"
                  sx={{
                    "& .MuiBadge-dot": {
                      backgroundColor: theme.palette.tertiary.main,
                    },
                  }}
                >
                  <Chip
                    variant="filled"
                    size="small"
                    label="coming soon"
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                    }}
                  />
                </Badge>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        id="features-section"
        sx={{
          // py: { xs: "100px", md: "100px" },
          px: { xs: 2, md: 4, lg: 0 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FeatureSection />
      </Box>

      <Box
        id="launch-section"
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: (theme) => theme.palette.secondary.main,
          py: { xs: "100px", md: "100px" },
          px: { xs: 4, md: 4, lg: 0 },
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{ maxWidth: "sm" }}
          alignItems="center"
          textAlign="center"
        >
          <Typography
            variant="h3"
            color="text.primary"
            display="block"
            gutterBottom
          >
            {" "}
            <b>
              Launching soon{" "}
              <RocketLaunchIcon fontSize="40" color="tertiary" sx={{ ml: 1 }} />
            </b>
          </Typography>

          <Typography sx={{ pb: 2 }}>
            {" "}
            Curate is now open in beta with limited features. If you're eager to
            test it out, you're invited to dive in now. Any and all feedback is
            most welcome! For those who prefer the full experience, sign up to
            be notified of the official launch.
          </Typography>
          <Typography></Typography>

          <Stack direction="row" spacing={3}>
            {user ? (
              <Button
                variant="contained"
                component={ReactRouterLink}
                to="/app/library"
              >
                Go to beta
              </Button>
            ) : (
              <Button variant="contained" onClick={login}>
                Try beta now
              </Button>
            )}
            <Button
              variant="contained"
              color="tertiary"
              onClick={() =>
                window.open("https://app.youform.com/forms/yyajnte3", "_blank")
              }
            >
              Get notified
            </Button>
          </Stack>
        </Stack>
      </Box>

      <Box
        id="about-section"
        sx={{
          // backgroundColor: (theme) => theme.palette.secondary.main,
          py: { xs: "50px", md: "100px" },
          px: { xs: 4, md: 4, lg: 0 },
          display: "flex",
          justifyContent: "center",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Grid container maxWidth="lg" spacing={0}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              component="img"
              src={profilePic}
              alt="Founder"
              sx={{
                borderRadius: "50%", // circular crop
                maxWidth: { xs: 250, md: 400 },
                maxHeight: { xs: 250, md: 400 },
                mb: { xs: 4, md: 4 },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" color="text.primary" gutterBottom>
              <b>About</b>
            </Typography>
            <Typography gutterBottom>
              <b>Hello! My name’s Will</b> 👋 I’m an avid reader and movie
              enthusiast. For years, I’ve kept various lists in my phone’s notes
              app to track the content I’ve been consuming, as well as
              recommendations from friends and family.
            </Typography>
            <Typography gutterBottom>
              <b>Why not use existing apps? </b>The truth is, I did. But nothing
              really provided the simplicity, lack of friction and breadth of
              content I was after. Goodreads is a great idea, but it’s horrible
              to use. Letterboxd is a step in the right direction, but it’s just
              for movies. I wanted a single place to turn to where I could
              curate all my content interests; from books and movies to
              podcasts, YouTube videos and other stuff too.
            </Typography>
            <Typography gutterBottom>
              <b>And so the idea of Curate was born. </b> At the beginning of
              2024, I decided to quit my job and make it a reality. What started
              as a project to learn a bit of software development, quickly grew
              into something I knew I wanted to continue. So here we are!
            </Typography>
            <Typography gutterBottom>
              <b>Curate is a passion project, not a business. </b> I’m funding
              it myself and my only goal is to make the app I wished existed in
              the first place. So, if you’re the type of person who thrives on
              sharing and receiving recommendations, delights in experiencing
              the best of what they’re passionate about and loves the
              satisfaction of ticking items off their “to watch / read / listen
              / see” lists, Curate may well be for you too.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        id="get-involved-section"
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.main,
          py: { xs: "100px", md: "100px" },
          px: { xs: 2, md: 4, lg: 0 },
          display: "flex",
          justifyContent: "center",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Grid container maxWidth="lg" spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "right" },
            }}
          >
            <Typography variant="h3" color="text.primary" gutterBottom>
              <b>Get involved {isMobile ? "👇" : "👉"}</b>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography color="text.primary">
              Got great ideas?{" "}
              <Link
                href="https://app.youform.com/forms/cg9eliij"
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
              >
                Leave feedback
              </Link>
            </Typography>
            <Typography color="text.primary">
              Know how to implement them?{" "}
              <Link
                component="button"
                onClick={() => setOpenJoinDialog(true)}
                underline="hover"
              >
                Join the team
              </Link>
            </Typography>
            <Typography color="text.primary">
              Want to show support?{" "}
              <Link
                component="button"
                onClick={() => setOpenDonateDialog(true)}
                underline="hover"
              >
                Donate
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        id="TMDB-section"
        sx={{
          display: "flex",
          justifyContent: "center",
          py: { xs: "50px", md: "50px" },
          px: { xs: 4, md: 4, lg: 0 },
          // textAlign: { xs: "center", md: "left" },
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <img
            src="/tmdb-logo.png"
            alt="TMDB logo"
            width="104px"
            height="75px"
          />
          <Typography
            variant="body2"
            color="text.primary"
            sx={{
              maxWidth: "400px",
            }}
          >
            {" "}
            All movie metadata used in Curate is provided by The Movie Database
            (TMDB) but is not endorsed, certified, or otherwise approved by
            TMDB.
          </Typography>
        </Stack>
      </Box>

      <Box
        id="footer-section"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          pr: { xs: 0, md: 2 },
          pb: 2,
          // textAlign: { xs: "center", md: "left" },
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: "normal",
              typography: "body2",
            }}
            href="https://app.youform.com/forms/cg9eliij"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            👋 Contact us
          </Button>
          <Button
            component={ReactRouterLink}
            to="/privacy"
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: "normal",
              typography: "body2",
            }}
          >
            {" "}
            🔒 Privacy policy
          </Button>
          <Button
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: "normal",
              typography: "body2",
            }}
            onClick={() => scrollToSection("landing-section")}
          >
            {" "}
            👆 Back to top
          </Button>
        </Stack>
      </Box>

      <Dialog
        open={openJoinDialog}
        onClose={() => setOpenJoinDialog(false)}
        aria-labelledby="join-team-dialog"
      >
        <DialogTitle id="join-team-dialog">Join the Team</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Well, there isn't a team as such. It's just me! But if you are
            passionate about what I'm building with Curate and would like to
            contribute, please feel free to reach out to me on LinkedIn and we
            can discuss how to work together.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            href="https://www.linkedin.com/in/willblacklock/"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            variant="contained"
            startIcon={<LinkedInIcon />}
          >
            Let's chat
          </Button>
          <Button onClick={() => setOpenJoinDialog(false)} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDonateDialog}
        onClose={() => setOpenDonateDialog(false)}
        aria-labelledby="support-development-dialog"
      >
        <DialogTitle id="support-development-dialog">
          Support development
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            If you’d like to support Curate's development, please consider a
            small contribution. Your support goes directly towards hosting costs
            and developing future features.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            href="https://paypal.me/supportcurate/"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            variant="contained"
            startIcon={<VolunteerActivismIcon />}
          >
            Support
          </Button>
          <Button onClick={() => setOpenDonateDialog(false)} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Home;
