import React, { useState } from "react";
import Box from "@mui/material/Box";
import Library from "./routes/Library";
import Profile from "./routes/Profile";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";
import Test from "./routes/Test";
import Collections from "./routes/Collections";
import Explore from "./routes/Explore";

export default function Spa() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ResponsiveDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
        }}
      >
        <Container maxWidth="lg" sx={{ pb: 2 }}>
          <Routes>
            <Route
              path="/library"
              element={<Library toggleDrawer={handleDrawerToggle} />}
            />
            <Route
              path="/profile"
              element={<Profile toggleDrawer={handleDrawerToggle} />}
            />
            <Route
              path="/collections"
              element={<Collections toggleDrawer={handleDrawerToggle} />}
            />
            <Route
              path="/explore"
              element={<Explore toggleDrawer={handleDrawerToggle} />}
            />
            <Route path="/test" element={<Test />} />
          </Routes>
        </Container>
      </Box>
    </Box>
  );
}
