import React from "react";
import Home from "./routes/Home";
import Spa from "./spa.js";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./components/ProtectedRoute";
import OAuthCallback from "./routes/OAuthCallback";
import PrivacyPolicy from "./routes/PrivacyPolicy.js";
import ScrollToTop from "./components/ScrollToTop.js";

export default function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oauth2/callback" element={<OAuthCallback />} />
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <Spa />
            </ProtectedRoute>
          }
        />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </AuthProvider>
  );
}
