import { Box } from "@mui/material";
import React from "react";
import { useThemeContext } from "../context/themeContext";

export default function Loading() {
  const { mode } = useThemeContext();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: "50px",
          height: "50px",
          pointerEvents: "none",
          objectFit: "cover",
          border: "none",
          background: "transparent",
        }}
      >
        <source
          src={
            mode === "light"
              ? "/animations/Loading_Green_Light.webm"
              : "/animations/Loading_Green_Dark.webm"
          }
          type="video/webm"
        />
        Loading...
      </video>
    </Box>
  );
}
